.modal-drag {
    width: 610px;
    height: 540px;
    background-color: white;
    cursor: move;
    text-align: center;

}

/* 
.modal-body {
    width: 610px;
    height: 500px;
    object-fit: contain;
} */

.input {
    width: 35px;
    padding: 5px 5px;
    margin: 4px 2px;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    font-weight: 400;
    text-align: center;
}

.input-impaksi {
    width: 100px;
    padding: 5px 5px;
    margin: 4px 2px;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    font-weight: 400;
    text-align: center;
}

.input-impaksi::placeholder {
    font-size: 12px;
}


.input:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto;
}

.item {
    text-align: center;
}

.perekatan-font {
    font-size: 0.85rem;
}

.color-blue{
    background-color: #47B5FF;
}

.color-blue:focus{
    background-color: #47B5FF;
}

.color-yellow{
    background-color: #FFF6BF;
}

.color-yellow:focus{
    background-color: #FFF6BF;
}

.color-red{
    background-color: #FFD1D1;
}

.color-red:focus{
    background-color: #FFD1D1;
}

