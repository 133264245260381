

.login-form{
    background-color: #FFFFFF;
    height: 100%;
}

/* .login-title{
    background-color: #FFEDE1;
    height: 100%;
} */


.login-title{
    background-image: url('../images/dentist.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.login-layer{
    background-color: rgb(255,237,225,0.5);
    height: 100%;
}

.login-logo{
    background-image: url('../images/Logo.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;
    width: 600px;
    
    background-size: contain;

}