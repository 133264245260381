@font-face {
  font-family: "SoraSemiBold";
  src: url(./fonts/Sora/Sora-SemiBold.ttf);
}

@font-face {
  font-family: "SoraLight";
  src: url(./fonts/Sora/Sora-Light.ttf);
}

@font-face {
  font-family: "SoraMedium";
  src: url(./fonts/Sora/Sora-Medium.ttf);
}

@font-face {
  font-family: "Poppins Regular";
  src: url(./fonts/Poppins/Poppins-Regular.ttf);
}

* {
  margin: 0;
  /* font-family: "SoraSemiBold" */
  font-family: "Poppins Regular";
}

.primary {
  background-color: #4a88fa !important;
  color: #ffffff !important;
  border-color: #4a88fa !important;
}

.secondary {
  background-color: #ffddc7 !important;
  color: #ff6631 !important;
  border-color: #ffddc7 !important;
}

.font-black {
  color: #000000 !important;
}

.font-white {
  color: #ffffff !important;
}

.font-orange {
  color: #ffbd01 !important;
}

.font-disable {
  color: #8b7272 !important;
}

.font-red {
  color: #ec4785 !important;
}

.font-purple {
  color: #2a2c2f !important;
}

.font-cyan {
  color: #5fccf5 !important;
}

.third {
  background-color: #f1a955 !important;
  color: #ffffff !important;
  border-color: #f1a955 !important;
}

.red {
  background-color: #62da8b !important;
  color: #ffffff !important;
  border-color: #62da8b !important;
}

.purple {
  background-color: #4a88fa !important;
  color: #ffffff !important;
  border-color: #4a88fa !important;
}

.cyan {
  background-color: #5fccf5 !important;
  color: #ffffff !important;
  border-color: #5fccf5 !important;
}

.accordion-button:not(.collapsed) {
  color: #4a88fa !important;
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.form-check-input:checked {
  background-color: #4a88fa !important;
  border-color: #4a88fa !important;
}

.required {
  color: #c23a28;
}

.form-error-border {
  border: 1px solid #c23a28 !important;
}

.width-box {
  width: 50px !important;
}

.width-periodontal {
  width: 60px !important;
  font-size: 12px;
}

.width-impaksi {
  width: 120px !important;
  padding: 0 5px;
}

.width-label {
  width: 40px !important;
}

.input-center {
  text-align: center !important;
}

.light {
  font-family: "SoraLight";
}

.kuesioner-title-blue {
  padding: 10px;
  background-color: #94b2eb;
}

.kuesioner-title-yellow {
  padding: 10px;
  background-color: #94b2eb;
}

.popover {
  max-width: 800px !important;
}

.card-height {
  min-height: 200px;
}

.form-label {
  font-size: 14px !important;
}

.parter-title {
  width: 300px;
  height: 50px;
  background-color: #94b2eb;
  display: flex;
  align-items: center;
}

.no-margin {
  margin: 0;
}

.secondary-color {
  background-color: #eef4ff;
}

.search-input {
  /* width: 320px; */
  /* max-width: 600px; */
  width: 100%;
  /* min-width: 300px; */
  border-radius: 20px;
  margin-top: 10px;
  border: none;
  height: 30px;
  padding: 20px;
  background-color: #e7effe;
}

.search-input-left {
  width: 600px;
  border-radius: 20px;
  /* border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; */
  border: none;
  height: 30px;
  padding: 20px;
  background-color: #e7effe;
}

.search-input-right {
  margin-left: 1%;
  margin-right: 1%;
  width: 39%;
  border-radius: 20px;
  /* border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; */

  border: none;
  height: 30px;
  padding: 20px;
  background-color: #e7effe;
}

.search-input:focus {
  outline: none !important;
  /* border: 1px solid red; */
  box-shadow: 0 0 10px #94b2eb;
}

.search-input-left:focus {
  outline: none !important;
  /* border: 1px solid red; */
  box-shadow: 0 0 10px #94b2eb;
}

.search-input-right:focus {
  outline: none !important;
  /* border: 1px solid red; */
  box-shadow: 0 0 10px #94b2eb;
}

.button-search {
  border: none;
  background-color: #4a88fa;
  border-radius: 20px;
  margin-top: 10px;
  height: 40px;
  /* width: 100%; */
  width: 100px;

  font-size: 15px;
  /* font-weight: bold; */
  color: white;
}

.button-clear-search {
  border: none;
  margin-top: 10px;
  background-color: #b7bbc2;
  border-radius: 20px;
  height: 40px;
  /* width: 100%; */
  width: 100px;
  font-size: 15px;
  /* font-weight: bold; */
  color: white;
}

.search-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.button-search-container {
  width: 220px;
  display: inline-flex;
  justify-content: space-between;
}

.font-15 {
  font-size: 15px;
}

.form-control:disabled {
  background-color: #e7effe !important;
  opacity: 1;
}

@media (max-width: 840px) {
  .search-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .search-container .button-clear-search {
    margin-left: 5px;
  }
}
