

.sidebar {
    height: 100%;
    background-color: #94B2EB;
    text-align: center;
    padding: 0;
    position: fixed;
    width: 260px;
    transition: margin-left 0.5s;
    z-index: 2;
    
}

.sidebar-menu{
    height: 100%;
}

.sidebar.toggle {
    margin-left: -500px;
    transition: margin-left 0.5s;

}

.logo {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-image {
    width: 200px;
}

.sidebar__item {
    color: #ffffff;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    /* padding: 4px; */
    /* margin: 10px; */
}

.icon {
    position: relative;
    display: block;
    min-width: 20px;
    height: 30px;
    line-height: 30px;
    text-align: center;
}

.sidebar__item.active {
    position: relative;
    width: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #191919;
    background-color: #E7EFFE;

    /* border-left: 5px solid #FFBD01; */

    /* border-bottom-left-radius: 25px;
    border-top-left-radius: 25px; */

}

/* 
.sidebar__item:hover {
    color: #FFBD01;
    border-left: 5px solid #FFBD01;
} */

.sidebar__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.sidebar__menu_name {
    position: relative;
    display: block;
    padding-left: 10px;
    height: 30px;
    line-height: 31px;
    white-space: normal;
    text-align: center;
    color: #ffffff;
    /* font-family: "Acumin Pro Wide medium", sans-serif; */
}

.sidebar__menu_name.active {
    color: #191919;
}

.sidebar__menu_name:hover {
    color: #191919;
}

/* 
.account-info {
    height: 50px;
    background-color: #9FA3BD;
    width: 300px;
    cursor: pointer;
    text-transform: capitalize;
} */

.account-menu {
    height: 100px;
    width: 300px;
    background-color: #9FA3BD;
    display: none;
}

.account-menu.toggle {
    display: block;
}

.account-setting-logout {
    height: 50px;
    width: 300px;
    cursor: pointer;
    background-color: #313c47;
    color: white;
}

.account-logout {
    height: 50px;
    width: 300px;
    cursor: pointer;
    background-color: #212529;
}

.close-button {
    position: absolute;
    right: 0;
    display: none;
}

@media only screen and (max-width: 768px) {
    .sidebar {
        width: 100%;
        z-index: 10;
    }

    .sidebar.toggle {
        margin-left: -768px;

    }

    .close-button {
        position: absolute;
        right: 0;
        display: block;
        background-color: #94B2EB !important;
    }
}

.sidebar-section {
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
    padding: 10px 0;
}

.section-title {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 12px;
    text-transform: uppercase;
    color: #191919;
}

.sidebar__item b:nth-child(1) {
    position: absolute;
    top: -10px;
    height: 10px;
    width: 100%;
    background: #E7EFFE;

}

.sidebar__item b:nth-child(1)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 20px;
    background-color: #94B2EB;

}

.sidebar__item b:nth-child(2) {
    position: absolute;
    bottom: -10px;
    height: 10px;
    width: 100%;
    background: #E7EFFE;

}

.sidebar__item b:nth-child(2)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top-right-radius: 20px;
    background-color: #94B2EB;

}

.transparent{
    background-color: #94B2EB !important;
    border: none !important;
}