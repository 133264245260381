.layout {
    width: 100vw;
    height: 100vh;
    display: flex;
    position: relative;
}

body {
    margin: 0;
    padding: 0;
}

.main {
    width: 100%;
    margin-left: 260px;
    padding: 10px;
    transition: margin-left 0.5s;
    background-color: #E7EFFE;


}

.account-info {
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main.toggle {
    margin-left: 0;
    transition: margin-left 0.5s;
}

.toggle-button.toggle{
    left: 0;
    transition: left 0.5s;
}

.nav {
    height: 65px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    z-index: 2;
    background-color: white;
}

.nav__item p {
    margin: 0;
    overflow: hidden;
}

.nav__item {
    width: 250px;
    height: 65px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-items: center;
    text-align: right;
}

.image-item {
    height: 50px;
    width: 50px;
    background-color: brown;
    border-radius: 50%;
}

.username {
    width: 200px;
    height: auto;
}

.image {
    margin-left: 10px;
    width: 50px;
    height: auto;
    margin-right: 20px;
}

.main-outlet {
    margin-top: 90px;
    background-color: #E8F0FF;
}

.toggle-button {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    left: 260px;
    background-color: #EEF4FF;
    /* width: 100%; */
    height: 50px;
    transition: left 0.5s;

}

/* .setting-button {
    transition: transform 1s;
}

.setting-button:hover {
    transform: rotate(90deg);
    transition: transform 1s;
} */

.setting-button:hover+.account-setting {
    position: absolute;
    right: 0;
    margin-top: 25px;
    margin-right: 25px;
    background-color: #0f0f0f8c;
    color: #FFFFFF;
    width: 200px;
    height: 100px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.account-setting {
    display: none;
}

.account-setting:hover {
    position: absolute;
    right: 0;
    margin-top: 25px;
    margin-right: 25px;
    background-color: #0f0f0f8c;
    color: #FFFFFF;
    width: 200px;
    height: 100px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.account-setting-tablet {
    position: absolute;
    right: 0;
    margin-top: 25px;
    margin-right: 20px;
    background-color: #FFFFFF;
    width: 200px;
    height: 100px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.setting-item {
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.setting-item:hover {
    background-color: #FFFFFF;
    color: #000000;
    /* border-radius: 30px; */
}

.avatar-account {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 34px;
    height: 34px;
    background: #F1A955;
    border-radius: 6px;
    color: white;
    font-size: 15px;
}

.top-bar{
    background-color: #EEF4FF;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}


.toggle-color{
    background-color: #EEF4FF !important;
    border: none !important;

}

@media only screen and (max-width: 768px) {
    .main {
        margin-left: 0px;
    }
    
}