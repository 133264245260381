.mini-card {
  width: 240px;
  height: 150px;
  border-radius: 50px;
  
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.blue{
    background-color: #94B2EB;
}

.pink{
    background-color: #F890E3;
}

.orange{
    background-color: #F8C990;
}

.green{
    background-color: #BFF890;
}

h3{
    color: #FFFFFF;
    font-size: 1.3em;
    font-weight: 600;
    line-height: 1;
    padding-bottom: .5em;
    margin-top: 15px;
    border-bottom: 2px solid #FFFFFF;
}

h1{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 3.5em;
}

.info-total{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.filter-search{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    
}

.filter-place{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    /* margin-right: 15%; */

}

.filter-date{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 25%;

}

.fixed-field{
    width: 180px;
}

.chart-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 400px;
}

.item-chart-card{
    width: 45%;
    height: 400px;
}